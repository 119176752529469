import { useEffect, useMemo } from "react";
import FilterProps from "./FilterProps";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { dataTypeOperatorMap } from "../../../constants/customFilterDataType";
import * as filterOperators from "../../../constants/filterOperator";
import { groupingNameMap } from "../../../constants/filterGrouping";

import IconButton from "../../UI/IconButton";
import RadioButton from "../../UI/RadioButton";
import Toggle from "../../UI/Toggle";
import { AND, OR } from "../../../constants/customFilterType";
import Checkbox from "../../UI/Checkbox";

const BoolFilter = (props: FilterProps) => {

    const handleOnValueChange = (value: string) => {
        props.onValueChange!(props.id, value);
    };

    const handleOnDelete = () => {
        props.onDelete(props.id);
    };      
    
    useEffect(() => {
        if (props.showEndConjunction) {
            handleOnEndConjunctionChange(props.endConjunction);
        } else {
            handleOnEndConjunctionChange(undefined);
        }
    }, [props.showEndConjunction, props.endConjunction]);
    
    useEffect(() => {
        if (props.showStartConjunction) {
            handleOnStartConjunctionChange(props.startConjunction);
        } else {
            handleOnStartConjunctionChange(undefined);
        }
    }, [props.showStartConjunction, props.startConjunction]);

    const handleOnStartConjunctionChange = (conjunction?: string) => {
        props.onStartConjunctionChange(props.id, conjunction);
    };

    const handleOnEndConjunctionChange = (conjunction?: string) => {
        props.onEndConjunctionChange(props.id, conjunction);
    };

    const handleOnSelectedChanged = (selected: boolean) => {
        props.onSelectedChange!(props.id, selected);
    };

    return <>
        {props.showStartConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnStartConjunctionChange}
            value={props.startConjunction} />}
            <div className="row align-items-center justify-content-between">
                <div className="column-auto">
                    <Checkbox id={`filter-selected-${props.id}`}
                        checked={props.selected}
                        containerClassName="unboxed margin-top-0"
                        onChange={handleOnSelectedChanged} />
                </div>
                <div className="column-auto">
                    <IconButton id={`delete-filter-${props.id}`}
                        icon={<i className="ri-delete-bin-line"></i>}
                        onClick={handleOnDelete}
                        className="small flat" />
                </div>
            </div>
        <div className="row padding-3">
            <div className="column-auto text-bold">
                {groupingNameMap[props.grouping]}
            </div>
        </div>
        <div className="row justify-content-between align-items-center padding-3">
            <div className="column-3">
                {props.name}
            </div>
            <div className="column">
                <RadioButton id={`bool-filter-id-${props.id}`}
                    options={[{value:'yes', label: 'Yes'}, {value: 'no', label: 'No'}]}
                    value={props.value}
                    onChange={handleOnValueChange} />
            </div>
        </div>
        {props.showEndConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnEndConjunctionChange}
            value={props.endConjunction} />}
    </>
};

export default BoolFilter;