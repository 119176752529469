import { EVERYTHING, IN_PROGRESS_GOALS } from "../constants/planScopes"
import * as reportPerspectives from "../constants/reportPerspective"
import * as reportSteps from "../constants/reportStep"
import { ASSESSMENTS, DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES, PLANS } from "../constants/reportTypes"

const mapping: { [key: string]: { step: string, routeByValues: boolean }} = {
    [PLANS]: { step: reportSteps.PLAN_SCOPE, routeByValues: true },
    [`${PLANS}/${reportSteps.PLAN_SCOPE}[${IN_PROGRESS_GOALS}]`]: { step: reportSteps.DONE, routeByValues: true },
    [`${PLANS}/${reportSteps.PLAN_SCOPE}[${EVERYTHING}]`]: { step: reportSteps.ACTIVE_DATE_RANGE, routeByValues: false },
    [`${PLANS}/${reportSteps.PLAN_SCOPE}[${EVERYTHING}]/${reportSteps.ACTIVE_DATE_RANGE}`]: { step: reportSteps.DONE, routeByValues: false },
    [DEMOGRAPHICS]: { step: reportSteps.REPORT_PERSPECTIVE, routeByValues: true },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.CUSTOM_FILTER}]`]: { step: reportSteps.CUSTOM_FILTER, routeByValues: false },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.CUSTOM_FILTER}]/${reportSteps.CUSTOM_FILTER}`]: { step: reportSteps.DONE, routeByValues: false },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.ORGANIZATION}]`]: { step: reportSteps.ACTIVE_DATE_RANGE, routeByValues: false },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.ORGANIZATION}]/${reportSteps.ACTIVE_DATE_RANGE}`]: { step: reportSteps.DONE, routeByValues: false },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.PROGRAMS}]`]: { step: reportSteps.PROGRAM_NAME, routeByValues: false },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.PROGRAMS}]/${reportSteps.PROGRAM_NAME}`]: { step: reportSteps.ENROLL_DATE_RANGE, routeByValues: false },
    [`${DEMOGRAPHICS}/${reportSteps.REPORT_PERSPECTIVE}[${reportPerspectives.PROGRAMS}]/${reportSteps.PROGRAM_NAME}/${reportSteps.ENROLL_DATE_RANGE}`]: { step: reportSteps.DONE, routeByValues: false },
    [OUTPUTS_AND_OUTCOMES]: { step: reportSteps.OUTPUTS_AND_OUTCOMES_FORM_SELECTION, routeByValues: false },
    [`${OUTPUTS_AND_OUTCOMES}/${reportSteps.OUTPUTS_AND_OUTCOMES_FORM_SELECTION}`]: { step: reportSteps.ACTIVITY_DATE_RANGE, routeByValues: false },
    [`${OUTPUTS_AND_OUTCOMES}/${reportSteps.OUTPUTS_AND_OUTCOMES_FORM_SELECTION}/${reportSteps.ACTIVITY_DATE_RANGE}`]: { step: reportSteps.DONE, routeByValues: false },
    [ASSESSMENTS]: { step: reportSteps.ASSESSMENT_FORM_SELECTION, routeByValues: false },
    [`${ASSESSMENTS}/${reportSteps.ASSESSMENT_FORM_SELECTION}`]: { step: reportSteps.ACTIVITY_DATE_RANGE, routeByValues: false },
    [`${ASSESSMENTS}/${reportSteps.ASSESSMENT_FORM_SELECTION}/${reportSteps.ACTIVITY_DATE_RANGE}`]: { step: reportSteps.DONE, routeByValues: false }
}

const getNextStep = (locator: string): { step: string, routeByValues: boolean } => {
    return mapping[locator];
} 

export {
    getNextStep
};