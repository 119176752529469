import { useEffect, useMemo, useState } from "react";
import FilterProps from "./FilterProps";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { groupingNameMap } from "../../../constants/filterGrouping";
import IconButton from "../../UI/IconButton";
import { searchPrograms } from "../../../services/programsService";
import Program from "../../../models/serviceModels/Program";
import Loader from "../../UI/Loader";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import Toggle from "../../UI/Toggle";
import { AND, OR } from "../../../constants/customFilterType";
import Checkbox from "../../UI/Checkbox";

const ProgramEnrollmentDateRangeFilter = (props: FilterProps) => {
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState();
    const [programs, setPrograms] = useState<Program[]>([]);

    useEffect(() => {
        setLoading(true);

        searchPrograms({ take: 1000}).then(r => {
            setPrograms(r.data);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);      
    
    useEffect(() => {
        if (props.showEndConjunction) {
            handleOnEndConjunctionChange(props.endConjunction);
        } else {
            handleOnEndConjunctionChange(undefined);
        }
    }, [props.showEndConjunction, props.endConjunction]);
    
    useEffect(() => {
        if (props.showStartConjunction) {
            handleOnStartConjunctionChange(props.startConjunction);
        } else {
            handleOnStartConjunctionChange(undefined);
        }
    }, [props.showStartConjunction, props.startConjunction]);

    const handleOnStartConjunctionChange = (conjunction?: string) => {
        props.onStartConjunctionChange(props.id, conjunction);
    };

    const handleOnEndConjunctionChange = (conjunction?: string) => {
        props.onEndConjunctionChange(props.id, conjunction);
    };

    const parsedValue: { from: string, to: string, program: '' } = useMemo(() => {
        const defaultValue = { from: '', to: '' };
        if (!props.value || props.value.indexOf('{') === -1) {
            return defaultValue
        }

        try {

            const valueJson = JSON.parse(props.value);
            if (!valueJson.hasOwnProperty('from')) {
                return defaultValue;
            }

            if (!valueJson.hasOwnProperty('to')) {
                return defaultValue;
            }

            if (!valueJson.hasOwnProperty('program')) {
                return defaultValue;
            }

            return valueJson;
        } catch (e) {
            return defaultValue;
        }
    }, [props.value]);

    const handleOnValueChange = (value: string, type: 'from' | 'to' | 'program') => {
        props.onValueChange!(props.id, JSON.stringify({
            ...parsedValue,
            [type]: value
        }));
    };

    const handleOnDelete = () => {
        props.onDelete(props.id);
    };

    const handleOnSelectedChanged = (selected: boolean) => {
        props.onSelectedChange!(props.id, selected);
    };

    if (loading) {
        return <Loader />;
    }

    return <>
        {props.showStartConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnStartConjunctionChange}
            value={props.startConjunction} />}
        <ApiErrorMessages error={apiError} />
            <div className="row align-items-center justify-content-between">
                <div className="column-auto">
                    <Checkbox id={`filter-selected-${props.id}`}
                        checked={props.selected}
                        containerClassName="unboxed margin-top-0"
                        onChange={handleOnSelectedChanged} />
                </div>
                <div className="column-auto">
                    <IconButton id={`delete-filter-${props.id}`}
                        icon={<i className="ri-delete-bin-line"></i>}
                        onClick={handleOnDelete}
                        className="small flat" />
                </div>
            </div>
        <div className="row padding-3">
            <div className="column-auto text-bold">
                {groupingNameMap[props.grouping]}
            </div>
        </div>
        <div className="row justify-content-between align-items-center padding-3">
            <div className="column-3">
                {props.name}
            </div>
            <div className="column">
                <div className="row margin-2">
                    <div className="column">
                        <Select id={`filter-program-${props.id}`} 
                            value={parsedValue.program} 
                            onChange={v => handleOnValueChange(v, 'program')} label="Program">
                                <option>Select a Program</option>
                                {programs.map(p => {
                                    return <option key={p.id} value={p.id}>{p.name}</option>
                                })}
                            </Select>
                    </div>
                </div>
                <div className="row margin-2">
                    <div className="column">
                        <Input id={`date-filter-from-${props.id}`}
                            value={parsedValue.from}
                            onChange={v => handleOnValueChange(v, 'from')}
                            type="date" 
                            label="From" />
                    </div>
                    <div className="column">
                        <Input id={`date-filter-to-${props.id}`}
                            value={parsedValue.to}
                            onChange={v => handleOnValueChange(v, 'to')}
                            type="date" 
                            label="To" />
                    </div>
                </div>
            </div>
        </div>
        {props.showEndConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnEndConjunctionChange}
            value={props.endConjunction} />}
    </>
};

export default ProgramEnrollmentDateRangeFilter;