import { ACTIVITY_DATE_ID, CHECKBOX_GROUP_ID, CHECKBOX_ID, DATE_ID, MULTI_LINE_TEXT_ID, NOTES_ID, NUMBER_ID, RADIO_BUTTON_ID, SELECT_LIST_ID, TEXT_ID, TIME_SPENT_ID } from './fieldTypes';
import * as filterOperators from './filterOperator';

export const STRING = 'STRING';
export const STRING_WITH_POSSIBLE_VALUES = 'STRING_WITH_POSSIBLE_VALUES';
export const BOOLEAN = 'BOOLEAN';
export const NUMBER = 'NUMBER';
export const DATE = 'DATE';

export const dataTypeOperatorMap: {[key:string]: string[]} = {
    [STRING]: [
        filterOperators.EQUALS,
        filterOperators.NOT_EQUALS,
        filterOperators.CONTAINS,
        filterOperators.STARTS_WITH,
        filterOperators.ENDS_WITH,
        filterOperators.NOT_CONTAINS,
        filterOperators.NOT_STARTS_WITH,
        filterOperators.NOT_ENDS_WITH
    ],
    [STRING_WITH_POSSIBLE_VALUES]: [
        filterOperators.EQUALS,
        filterOperators.NOT_EQUALS,
        filterOperators.UNANSWERED
    ],
    [DATE]: [
        filterOperators.BETWEEN,
        filterOperators.EQUALS,
        filterOperators.NOT_EQUALS,
        filterOperators.GREATER_THAN,
        filterOperators.GREATER_THAN_OR_EQUAL_TO,
        filterOperators.LESS_THAN,
        filterOperators.LESS_THEN_OR_EQUAL_TO
    ],
    [BOOLEAN]: [
        filterOperators.IS_TRUE,
        filterOperators.IS_FALSE
    ],
    [NUMBER]: [
        filterOperators.EQUALS,
        filterOperators.NOT_EQUALS,
        // filterOperators.NOT_IN,
        // filterOperators.IN,
        filterOperators.GREATER_THAN,
        filterOperators.GREATER_THAN_OR_EQUAL_TO,
        filterOperators.LESS_THAN,
        filterOperators.LESS_THEN_OR_EQUAL_TO
    ]
};

export const fieldTypeOperatorMap: {[key: number]: string[]} = {
    [TEXT_ID]: dataTypeOperatorMap[STRING],
    [RADIO_BUTTON_ID]: dataTypeOperatorMap[STRING_WITH_POSSIBLE_VALUES],
    [CHECKBOX_ID]: dataTypeOperatorMap[BOOLEAN],
    [CHECKBOX_GROUP_ID]: dataTypeOperatorMap[STRING_WITH_POSSIBLE_VALUES],
    [SELECT_LIST_ID]: dataTypeOperatorMap[STRING_WITH_POSSIBLE_VALUES],
    [MULTI_LINE_TEXT_ID]: dataTypeOperatorMap[STRING],
    [ACTIVITY_DATE_ID]: dataTypeOperatorMap[DATE],
    [NOTES_ID]: dataTypeOperatorMap[STRING],
    [TIME_SPENT_ID]: dataTypeOperatorMap[NUMBER],
    [DATE_ID]: dataTypeOperatorMap[DATE],
    [NUMBER_ID]: dataTypeOperatorMap[NUMBER]
};