import { validate } from "uuid";
import CustomFilterType from "../models/CustomFilterType";
import { getEthnicities, getGenders, getRaces, getRelationships } from "../services/clientsService";
import { getAvailablePrograms, searchPrograms } from "../services/programsService";
import * as filterDataTypes from "./customFilterDataType";
import { HOUSEHOLD_MEMBER, LOGICAL, PARTICIPANT, PROGRAMS } from "./filterGrouping";
import { DEMOGRAPHICS } from "./reportTypes";
import states from "./states";

export const PARTICIPANT_BIRTH_DATE = 'PARTICIPENT_BIRTH_DATE';
export const PARTICIPANT_GENDER = 'PARTICIPANT_GENDER';
export const PARTICIPANT_ETHNICITY = 'PARTICIPANT_ETHNICITY';
export const PARTICIPANT_RACE = 'PARTICIPANT_RACE';
export const PARTICIPANT_CITY = 'PARTICIPANT_CITY';
export const PARTICIPANT_STATE = 'PARTICIPANT_STATE';
export const PARTICIPANT_ZIP = 'PARTICIPANT_ZIP';
export const PARTICIPANT_CREATED = 'PARTICIPANT_CREATED';
export const PARTICIPANT_ACTIVATED = 'PARTICIPANT_ACTIVATED';
export const PARTICIPANT_IS_ACTIVATED = 'PARTICIPANT_IS_ACTIVATED';
export const PARTICIPANT_DEACTIVATED = 'PARTICIPANT_DEACTIVATED';
export const PARTICIPANT_IS_DEACTIVATED = 'PARTICIPANT_IS_DEACTIVATED';
export const PARTICIPANT_ADDITIONAL_INFORMATION = 'PARTICIPANT_ADDITIONAL_INFORMATION';
export const HOUSEHOLD_MEMBER_BIRTH_DATE = 'HOUSEHOLD_MEMBER_BIRTH_DATE';
export const HOUSEHOLD_MEMBER_GENDER = 'HOUSEHOLD_MEMBER_GENDER';
export const HOUSEHOLD_MEMBER_ETHNICITY = 'HOUSEHOLD_MEMBER_ETHNICITY';
export const HOUSEHOLD_MEMBER_RACE = 'HOUSEHOLD_MEMBER_RACE';
export const HOUSEHOLD_MEMBER_RELATIONSHIP = 'HOUSEHOLD_MEMBER_RELATIONSHIP';
export const PROGRAM_ENROLLED_IN = 'PROGRAM_ENROLLED_IN';
export const PROGRAM_ENROLLED_DATE = 'PROGRAM_ENROLLED_DATE';
export const PROGRAM_UNENROLLED_DATE = 'PROGRAM_UNENROLLED_DATE';
export const PROGRAM_ENROLLED_DURING_DATE = 'PROGRAM_ENROLLED_DURING_DATE';
export const RIGHT_PARENTHESIS = 'RIGHT_PARENTHESIS';
export const LEFT_PARENTHESIS = 'LEFT_PARENTHESIS';
export const AND = 'AND';
export const OR = 'OR';

export const filters: CustomFilterType[] = [
    {
        type: PARTICIPANT_BIRTH_DATE,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.DATE,
        isExtenalLookup: false,
        name: 'Birth Date'
    },
    {
        type: PARTICIPANT_GENDER,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getGenders().then(g => {
            return g.map(g1 => {
                return {
                    label: g1.name,
                    value: g1.id.toString()
                }
            })
        }),
        isExtenalLookup: false,
        name: 'Gender'
    },
    {
        type: PARTICIPANT_ETHNICITY,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getEthnicities().then(e => {
            return e.map(e1 => {
                return {
                    value: e1.id.toString(),
                    label: e1.name
                }
            });
        }),
        isExtenalLookup: false,
        name: 'Ethnicity'
    },
    {
        type: PARTICIPANT_RACE,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getRaces().then(r => {
            return r.map(r1 => {
                return {
                    value: r1.id.toString(),
                    label: r1.name
                }
            })
        }),
        isExtenalLookup: false,
        name: 'Race'
    },
    {
        type: PARTICIPANT_CITY,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING,
        isExtenalLookup: false,
        name: 'Address City'
    },
    {
        type: PARTICIPANT_STATE,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => Promise.resolve(states),
        isExtenalLookup: false,
        name: 'Address State'
    },
    {
        type: PARTICIPANT_ZIP,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING,
        isExtenalLookup: false,
        name: 'Address Zip'
    },
    {
        type: PARTICIPANT_CREATED,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.DATE,
        isExtenalLookup: false,
        name: 'Created Date'
    },
    {
        type: PARTICIPANT_ACTIVATED,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.DATE,
        isExtenalLookup: false,
        name: 'Activated Date'
    },
    {
        type: PARTICIPANT_IS_ACTIVATED,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.BOOLEAN,
        isExtenalLookup: false,
        name: 'Is Activated'
    },
    {
        type: PARTICIPANT_DEACTIVATED,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.DATE,
        isExtenalLookup: false,
        name: 'Deactivated Dated'
    },
    {
        type: PARTICIPANT_IS_DEACTIVATED,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.BOOLEAN,
        isExtenalLookup: false,
        name: 'Is Deactivated'
    },
    {
        type: PARTICIPANT_ADDITIONAL_INFORMATION,
        grouping: PARTICIPANT,
        reportType: DEMOGRAPHICS,
        dataType: null,
        isExtenalLookup: true,
        name: 'Additional Information'
    },
    {
        type: HOUSEHOLD_MEMBER_BIRTH_DATE,
        grouping: HOUSEHOLD_MEMBER,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.DATE,
        isExtenalLookup: false,
        name: 'Birth Date'
    },
    {
        type: HOUSEHOLD_MEMBER_GENDER,
        grouping: HOUSEHOLD_MEMBER,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getGenders().then(g => {
            return g.map(g1 => {
                return {
                    label: g1.name,
                    value: g1.id.toString()
                }
            })
        }),
        isExtenalLookup: false,
        name: 'Gender'
    },
    {
        type: HOUSEHOLD_MEMBER_ETHNICITY,
        grouping: HOUSEHOLD_MEMBER,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getEthnicities().then(e => {
            return e.map(e1 => {
                return {
                    value: e1.id.toString(),
                    label: e1.name
                }
            });
        }),
        isExtenalLookup: false,
        name: 'Ethnicity'
    },
    {
        type: HOUSEHOLD_MEMBER_RACE,
        grouping: HOUSEHOLD_MEMBER,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getRaces().then(r => {
            return r.map(r1 => {
                return {
                    value: r1.id.toString(),
                    label: r1.name
                }
            })
        }),
        isExtenalLookup: false,
        name: 'Race'
    },
    {
        type: HOUSEHOLD_MEMBER_RELATIONSHIP,
        grouping: HOUSEHOLD_MEMBER,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => getRelationships().then(r => {
            return r.map(r1 => {
                return {
                    value: r1.id.toString(),
                    label: r1.name
                }
            })
        }),
        isExtenalLookup: false,
        name: 'Relationsip to Participant'
    },
    {
        type: PROGRAM_ENROLLED_IN,
        grouping: PROGRAMS,
        reportType: DEMOGRAPHICS,
        dataType: filterDataTypes.STRING_WITH_POSSIBLE_VALUES,
        possibleValues: () => searchPrograms({ take: 1000 }).then(r => {
            return r.data.map(r1 => {
                return {
                    value: r1.id,
                    label: r1.name
                }
            })
        }),
        isExtenalLookup: false,
        name: 'Currently Enrolled In'
    },

    {
        type: PROGRAM_ENROLLED_DATE,
        grouping: PROGRAMS,
        reportType: DEMOGRAPHICS,
        dataType: null,
        isExtenalLookup: false,
        name: 'Enrollment Started'
    },
    {
        type: PROGRAM_UNENROLLED_DATE,
        grouping: PROGRAMS,
        reportType: DEMOGRAPHICS,
        dataType: null,
        isExtenalLookup: false,
        name: 'Enrollment Ended'
    },
    {
        type: PROGRAM_ENROLLED_DURING_DATE,
        grouping: PROGRAMS,
        reportType: DEMOGRAPHICS,
        dataType: null,
        isExtenalLookup: false,
        name: 'Enrolled During'
    }
];