import { useEffect, useState } from "react";
import FilterProps from "./FilterProps";
import Input from "../../UI/Input";
import Select from "../../UI/Select";
import { dataTypeOperatorMap } from "../../../constants/customFilterDataType";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import Loader from "../../UI/Loader";
import { operatorLabelMap, UNANSWERED } from "../../../constants/filterOperator";
import { groupingNameMap } from "../../../constants/filterGrouping";
import IconButton from "../../UI/IconButton";
import Toggle from "../../UI/Toggle";
import Checkbox from "../../UI/Checkbox";
import { AND, OR } from "../../../constants/customFilterType";

const StringFilter = (props: FilterProps) => {
    const [possibleValues, setPossibleValues] = useState<{ label: string, value: string }[]>();
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        if (!props.possibleValues) {
            return;
        }

        setLoading(true);
        props.possibleValues().then(v => {
            setPossibleValues(v);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, [props.possibleValues]);

    useEffect(() => {
        if (props.showEndConjunction) {
            handleOnEndConjunctionChange(props.endConjunction);
        } else {
            handleOnEndConjunctionChange(undefined);
        }
    }, [props.showEndConjunction, props.endConjunction]);
    
    useEffect(() => {
        if (props.showStartConjunction) {
            handleOnStartConjunctionChange(props.startConjunction);
        } else {
            handleOnStartConjunctionChange(undefined);
        }
    }, [props.showStartConjunction, props.startConjunction]);

    const handleOnStartConjunctionChange = (conjunction?: string) => {
        props.onStartConjunctionChange(props.id, conjunction);
    };

    const handleOnEndConjunctionChange = (conjunction?: string) => {
        props.onEndConjunctionChange(props.id, conjunction);
    };

    const handleOnOperatorChange = (operator: string) => {
        if (operator === UNANSWERED) {
            props.onValueChange!(props.id, '');
        }
        props.onOperatorChange!(props.id, operator);
    };

    const handleOnValueChange = (value: string) => {
        props.onValueChange!(props.id, value);
    };

    const handleOnDelete = () => {
        props.onDelete(props.id);
    };

    const handleOnSelectedChanged = (selected: boolean) => {
        props.onSelectedChange!(props.id, selected);
    };

    if (loading) {
        return <Loader />
    }

    return <>
        {props.showStartConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnStartConjunctionChange}
            value={props.startConjunction} />}
        <ApiErrorMessages error={apiError} />
        <div className="row align-items-center justify-content-between">
            <div className="column-auto">
                <Checkbox id={`filter-selected-${props.id}`}
                    checked={props.selected}
                    containerClassName="unboxed margin-top-0"
                    onChange={handleOnSelectedChanged} />
            </div>
            <div className="column-auto">
                <IconButton id={`delete-filter-${props.id}`}
                    icon={<i className="ri-delete-bin-line"></i>}
                    onClick={handleOnDelete}
                    className="small flat" />
            </div>
        </div>
        <div className="row padding-3">
            <div className="column-auto text-bold">
                {groupingNameMap[props.grouping]}
            </div>
        </div>
        <div className="row justify-content-between align-items-center padding-3">
            <div className="column-3">
                {props.name}
            </div>
            <div className="column-3">
                <Select id={`filter-operation-${props.id}`} value={props.operator} onChange={handleOnOperatorChange}>
                    <option>Select an Operator</option>
                    {dataTypeOperatorMap[props.dataType!].map((m, i) => {
                        return <option key={i} value={m}>{operatorLabelMap[m]}</option>
                    })}
                </Select>
            </div>
            <div className="column-6">
                {possibleValues && <Select id={`filter-select-${props.id}`} value={props.value} disabled={props.operator === UNANSWERED} onChange={handleOnValueChange}>
                    <option>Select a Value</option>
                    {possibleValues.map(pv => {
                        return <option key={pv.value} value={pv.value}>{pv.label}</option>
                    })}
                </Select>}
                {!possibleValues && <Input id={`filter-input-${props.id}`}
                    value={props.value}
                    onChange={handleOnValueChange} />}
            </div>
        </div>
        {props.showEndConjunction && <Toggle id={`conjunction-${props.id}`}
            option1={{ label: 'And', value: AND }}
            option2={{ label: 'Or', value: OR }}
            onChange={handleOnEndConjunctionChange}
            value={props.endConjunction} />}
    </>
};

export default StringFilter;