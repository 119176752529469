import { customReportFiltersEnabled } from "../utils/features";
import { DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES } from "./reportTypes";

const ORGANIZATION = 'ORGANIZATION';
const PROGRAMS = 'PROGRAMS';
const FORMS = 'FORMS';
const CUSTOM_FILTER = 'CUSTOM_FILTER';

const DEMOGRAPHICS_PERSPECTIVES = [
    ORGANIZATION,
    PROGRAMS,
    CUSTOM_FILTER
];

const DEMOGRAPHICS_PERSPECTIVES_WITHOUT_CUSTOM_FILTER = [
    ORGANIZATION,
    PROGRAMS
];

const SUBMITTED_FORMS_PERSPECTIVES = [
    ORGANIZATION,
    PROGRAMS
];

const PERSPECTIVE_NAME_MAP: {[key: string]: string} = {
    [FORMS]: 'Submitted Forms',
    [ORGANIZATION]: 'Entire Organization',
    [PROGRAMS]: 'Program Enrollment',
    [CUSTOM_FILTER]: 'Custom Filter'
};

const reportTypePerspectivesMap: { [key: string]: string[] } = {
    [DEMOGRAPHICS]: customReportFiltersEnabled ? DEMOGRAPHICS_PERSPECTIVES : DEMOGRAPHICS_PERSPECTIVES_WITHOUT_CUSTOM_FILTER,
    [OUTPUTS_AND_OUTCOMES]: SUBMITTED_FORMS_PERSPECTIVES
};

export {
    ORGANIZATION,
    PROGRAMS,
    FORMS,
    CUSTOM_FILTER,
    DEMOGRAPHICS_PERSPECTIVES,
    SUBMITTED_FORMS_PERSPECTIVES,
    PERSPECTIVE_NAME_MAP,
    reportTypePerspectivesMap
};