import * as dataTypes from "../../../constants/customFilterDataType"
import * as filterTypes from "../../../constants/customFilterType"
import AdditionalInformationFilter from "./AdditionalInformationFilter"
import BoolFilter from "./BoolFilter"
import DateFilter from "./DateFilter"
import FilterProps from "./FilterProps"
import ProgramEnrollmentDateRangeFilter from "./ProgramEnrollmentDateRangeFilter"
import StringFilter from "./StringFilter"

export const dataTypeTofilterMap: { [key: string]: (props: FilterProps) => JSX.Element } = {
    [dataTypes.STRING]: StringFilter,
    [dataTypes.STRING_WITH_POSSIBLE_VALUES]: StringFilter,
    [dataTypes.DATE]: DateFilter,
    [dataTypes.BOOLEAN]: BoolFilter
};

export const filterTypeToFilterMap: { [key: string]: (props: FilterProps) => JSX.Element } = {
    [filterTypes.PROGRAM_ENROLLED_DATE]: ProgramEnrollmentDateRangeFilter,
    [filterTypes.PROGRAM_UNENROLLED_DATE]: ProgramEnrollmentDateRangeFilter,
    [filterTypes.PROGRAM_ENROLLED_DURING_DATE]: ProgramEnrollmentDateRangeFilter,
    [filterTypes.PARTICIPANT_ADDITIONAL_INFORMATION]: AdditionalInformationFilter
};